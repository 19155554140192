<template>
  <div class="sand-page">
		<div class="top"></div>
		<!-- <div class="title">
			南宸玖峯净用地面积74425m²(约112亩） 计容面积约26.5万m²<br>北地块和中地块为住宅用地，南地块为商服及零售商业用地
		</div> -->
		<div class="loading" v-if="showLoading"><div class="loader"></div><i class="iconfont icon-hudongquwei"></i></div>
    <div id="sand"></div>

		<div class="guide" v-if="guide" @click="hideGuide"></div>
	</div>
</template>
<script>
import { mapState } from 'vuex'
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

var scene;
var controls;

export default {
	name: "Sand",
  data() {
    return {
			guide: false,
			camera: null,
			// scene: null,
			renderer: null,
			object: null,
			frame: null,
			// controls: null,
			house: 'https://dotway.oss-cn-beijing.aliyuncs.com/ncjf/house/sand/sand-processed.gltf',
			showLoading: true
    }
	},
	computed: mapState([
		'openMenu'
	]),
	mounted() {
		this.guide = localStorage.getItem('sand_guide') ? false : true
    this.init();
    this.animate();
	},
	beforeDestroy(){
		console.log('beforeDestroy');
		scene.remove(this.object);
		this.renderer.dispose();
		this.renderer.forceContextLoss();
		this.renderer = null;
		cancelAnimationFrame(this.frame);
	},
  methods: {
		hideGuide(){
      this.guide = false
      localStorage.setItem('sand_guide',true)
    },
    //初始化
    init: function() {
      //  创建场景对象Scene
      scene = new THREE.Scene();
      /**
       * 相机设置
       */
      let container = document.getElementById("sand");
      this.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 2000 );
			this.camera.position.set( 600, 700, 400 );

			let ambientLight = new THREE.AmbientLight(0xffffff);
			scene.add(ambientLight);
			// let light = new THREE.DirectionalLight( 0xffffff );
			// light.position.set( 0, 200, 0 );
			// light.castShadow = true;
			// light.shadow.camera.top = 180;
			// light.shadow.camera.bottom = - 100;
			// light.shadow.camera.left = - 120;
			// light.shadow.camera.right = 120;
			// scene.add( light );

			// model
			var loader = new GLTFLoader();
			var dracoLoader = new DRACOLoader();
			dracoLoader.setDecoderPath( '/libs/draco/gltf/' );
			loader.setDRACOLoader( dracoLoader );
			loader.load( this.house,  ( gltf ) => {
				this.object = gltf.scene;
				this.object.position.set(0,50,0);
				this.object.scale.set(1, 1, 1);
				this.object.updateMatrix();

				this.object.traverse( function ( child ) {
					if ( child.isMesh ) {
						child.material.emissive =  child.material.color;
						child.material.emissiveMap = child.material.map ;
						// child.castShadow = true;
						// child.receiveShadow = true;
					}
				});
				scene.add( gltf.scene );
				dracoLoader.dispose();
				dracoLoader = null;
				loader = null;
				this.showLoading = false
			},
			// called while loading is progressing
			( xhr ) => {
				this.progressing = ( xhr.loaded / xhr.total * 100 ) + '%'
				console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
			},
			// called when loading has errors
			function ( error ) {
				console.log( 'An error happened', error );
			});

			/**
       * 创建渲染器对象
       */
			this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
			this.renderer.setPixelRatio( window.devicePixelRatio );
			this.renderer.setSize(container.clientWidth, container.clientHeight);
			this.renderer.setClearAlpha(0.0);
			this.renderer.gammaOutput = true;
			this.renderer.gammaFactor = 2.2;
			container.appendChild(this.renderer.domElement);
			
			//创建控件对象
			controls = new OrbitControls(this.camera, this.renderer.domElement);
			controls.target.set( 0, 50, 0 );
			controls.minDistance = 300;
			controls.maxDistance = 1500;
			controls.update();
    },

    // 动画
    animate: function() {
      this.frame = requestAnimationFrame(this.animate);
      this.renderer.render(scene, this.camera);
		}
	}
};
</script>
<style lang="scss">
@import "@/assets/styles/common.scss";

.sand-page{
	width: 100%;
	height: 100%;
	overflow: hidden;
	.top{
			position: absolute;
			top: 50px;
			left: 73px;
			@include bg('sand/header.png',230px,59px);
		}
	.title{
			position: absolute;
			left: 0;
			top: 90px;
			width: 100%;
			text-align: center;
			font-size: 10px;
			line-height: 1.5;
			color: var(--color-text);
		}
	#sand{
			width: 100%;
			height: 100%;
	}
	.loading{
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translateX(-50%);
		// background-color: rgba(255,255,255,0.8);
		width: 50px;
		height: 50px;
		text-align: center;

		.iconfont{
			display: inline-block;
			font-size: 24px;
			color: #fff;
			margin-top: 13px;
		}
	}

	.guide{
    position: fixed;
    top: 0;
    left: 0;
    // width: 100vw;
    // height: 100vh;
    @include bg('tips1.png',100vw,100vh);
    background-size: 347px 255px;
    z-index: 999;
  }
}
</style>
